import { useAppDispatch } from "@/lib/redux/hooks";
import { view } from "@/lib/redux/mobileMenu/mobileMenuSlice";
import { ArrowIcon, Button } from "./styles";

type Props = {
  tab: {
    title: string;
  };
};

const MenuTab: React.FC<Props> = (props) => {
  const { tab } = props;
  const dispatch = useAppDispatch();

  return (
    <Button onClick={() => dispatch(view(tab))}>
      <span>{tab.title}</span>
      <ArrowIcon />
    </Button>
  );
};

export default MenuTab;
